<template>
  <div>

    <div v-if="loading" class="spinner-wrapper">
      <div class="loader"></div>
    </div>


    <div v-else>
      <!-- Navbar stays persistent across all routes -->
      <NavbarComponent v-if="isAuthenticated && cookieSet" :showHeader="showHeader" />

      <!-- Main content changes based on the route -->
      <router-view :user="user" @login="handleLogin" />
    </div>
  
  </div>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { watch, ref, onMounted } from 'vue';
import { setCookie } from './helpers/cookies';
import { useRouter } from 'vue-router';


export default {
  components: {
    NavbarComponent
  },
  computed: {
    showHeader() {
      return this.$route.path !== '/purchase';
    }
  },
  setup() {
    const { isAuthenticated, user } = useAuth0();
    const isAuthenticatedRef = ref(isAuthenticated.value);
    const userRef = ref(user.value);
    const cookieSet = ref(false);
    const router = useRouter(); // Access the Vue Router
    const loading = ref(true); // Spinner control

    console.log('isAuthenticated:', isAuthenticated);

    watch([isAuthenticated, user], ([newAuthValue, newUserValue]) => {
      if (newAuthValue && Object.keys(newUserValue).length > 0) {
        loading.value = true;
        isAuthenticatedRef.value = newAuthValue;
        userRef.value = newUserValue;
        callApi(userRef.value);
      }
    }, { immediate: true }); // Run watcher immediately on setup

    async function callApi(userRef) {
      console.log('Calling API with user:', userRef);
      const response = await fetch(`${process.env.VUE_APP_API_URL}/api/createUsrOnLogin/${userRef.email}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error creating user');
      } else {
        const responseBody = await response.json();
        setCookie('token', responseBody.token, 7);
        cookieSet.value = true;
        router.push('/requests');
        loading.value = false;
      }
    }

    onMounted(() => {
      // Initialize loading to true while waiting for authentication and API call
      loading.value = false;
    });


    return {
      isAuthenticated,
      user,
      cookieSet,
      loading
    };
  },
};
</script>

<style scoped>
/* Spinner styles */
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full screen height */
}

/* Spinner styles */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
